import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import CodeFileMember, { MethodArgument } from '../../../models/CodeFileMember';
import FileReferenceDefinition from '../../../models/FileReferenceDefinition';
import getFileReferenceLink from '../../../utils/getFileReferenceLink';
import CodeExampleBox from '../../CodeExampleBox';
import WarningBox from '../../WarningBox';
import './styles.scss';

interface CodeFileMemberProps {
  fileReferenceDefinitions: FileReferenceDefinition[];
  members: CodeFileMember[];
  title: string;
}

export default class MemberSection extends Component<CodeFileMemberProps> {

  render() {
    return (
      <div className="member-section">
        <h2>{this.props.title}</h2>
        {this.props.members.map(this._renderMember)}
      </div>
    );
  }

  private _getNodesForType(typeName: string): Array<JSX.Element|string> {

    const typeNameWithoutExtraSymbols = typeName.replace('[]', '') // array brackets
                                                .replace('?', ''); // nullable annotation
    const fileReferenceLink = getFileReferenceLink(this.props.fileReferenceDefinitions, typeNameWithoutExtraSymbols, typeName);
    if (fileReferenceLink) {
      return [fileReferenceLink];
    }
    // Split by angle brackets but keep the angle brackets in the array
    // e.g. "EventHandler<EventArgs<string>>" => ["EventHandler", "<" , "EventArgs", "<", "string", ">", ">"]
    const tokensAndAngleBrackets = typeName.split(/(<|>)/g).filter(str => str);
    const nodes = tokensAndAngleBrackets.map(tokenOrAngleBracket => {
      if (tokenOrAngleBracket === '<' || tokenOrAngleBracket === '>') {
        return tokenOrAngleBracket;
      }
      const referenceLink = getFileReferenceLink(this.props.fileReferenceDefinitions, tokenOrAngleBracket.replace('[]', ''), tokenOrAngleBracket);
      return referenceLink || tokenOrAngleBracket;
    });
    return nodes;
  }

  private _renderDescription(member: CodeFileMember) {

    const { description } = member;
    if (typeof description === 'string') {
      return <ReactMarkdown className="description" source={description} />
    }
    return (
      <div className="description">
        {description}
      </div>
    )
  }

  private _renderExample(member: CodeFileMember) {

    if (!member.example) {
      return;
    }
    const examples = Array.isArray(member.example) ? member.example : [member.example];
    return examples.map((example, index) => <CodeExampleBox example={example} key={index}/>);
  }

  /**
  * Renders the full name, turning the names of other documented classes
  * into links for those pages. This method is like a more sophistocated
  * version of getFullMemberName() from CodeFileMember.ts.
  */
  private _renderFullName(member: CodeFileMember) {

    if (member.type === 'value') {
      // Full name doesn't need to be rendered for value types.
      return;
    }
    const args = (member.arguments || []).reduce((accumulator: Array<JSX.Element | string>, arg: MethodArgument, index) => {
      if (index) {
        accumulator.push(', ');
      }
      const { name, type, defaultValue} = arg;
      accumulator.push(<span key={name}>{this._getNodesForType(type)} {name}{defaultValue ? ` = ${defaultValue}` : ''}</span>)
      return accumulator;
    }, []);

    return (
      <code className="full-name">
        {member.static ? 'static' : null} {member.returns ? this._getNodesForType(member.returns) : null} {member.name}{member.type === 'method' ? <span>({args})</span> : null} {this._renderPropertyAccessors(member)}
      </code>
    );
  }

  private _renderMember = (member: CodeFileMember) => {
    return (
      <div key={member.name + JSON.stringify(member.arguments)}>
        <h3 id={member.name}>
          <a href={`#${member.name}`}>
            {member.name}
          </a>
        </h3>
        {this._renderFullName(member)}
        {this._renderDescription(member)}
        {this._renderExample(member)}
        {member.warnings && <WarningBox warnings={member.warnings}/>}
        {this._renderSeeAlso(member)}
      </div>
    );
  }

  private _renderPropertyAccessors(member: CodeFileMember) {

    const { propertyAccessors } = member;
    if (!propertyAccessors) {
      return;
    }
    const accessors = [] as string[];
    if (propertyAccessors.get) {
      accessors.push('get;');
    }
    if (propertyAccessors.set) {
      accessors.push('set;');
    }
    return `{ ${accessors.join(' ')} }`;
  }

  private _renderSeeAlso(member: CodeFileMember) {

    const { seeAlso } = member;
    if (!seeAlso || !seeAlso.length) {
      return;
    }
    if (Array.isArray(seeAlso)) {
      return (
        <div className="seealso">
          <strong>See also:</strong>
          <ul>
            {seeAlso.map(item => <li key={item}><ReactMarkdown source={item}/></li>)}
          </ul>
        </div>
      );
    }
    return <ReactMarkdown className="seealso" source={`**See also**: ${seeAlso}`}/>;
  }
}
