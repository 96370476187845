
export interface MethodArgument {
  name: string;
  type: string;
  defaultValue?: string;
}

export interface CodeExample {
  code: string;
  title?: string;
}

export default interface CodeFileMember {
  name: string;
  type: 'method'|'property'|'event'|'constructor'|'value';
  propertyAccessors?: {
    get?: boolean;
    set?: boolean;
  };
  returns?: string;
  static?: boolean;
  arguments?: MethodArgument[];
  description: string | JSX.Element;
  warnings?: string[];
  example?: string|CodeExample|CodeExample[];
  seeAlso?: string|string[]
}

export function getFullMemberName(member: CodeFileMember){

  let fullName = member.name;
  if (member.returns) {
    fullName = `${member.returns} ${fullName}`;
  }
  if (member.type === 'method') {
    let args = '';
    if (member.arguments) {
      args = member.arguments
                   .map(arg => {
                      const defaultValue = arg.defaultValue ? ` = ${arg.defaultValue}` : '';
                      return `${arg.type} ${arg.name}${defaultValue}`;
                    }).join(', ');
    }
    fullName += `(${args})`;
  }
  if (member.static) {
    fullName = 'static ' + fullName;
  }
  return fullName;
}
