import React from 'react';
import FileReferenceDefinition from '../FileReferenceDefinition';
import HashLink from '../../components/HashLink';

const definition: FileReferenceDefinition = {
  name: 'PopupMode',
  type: 'enum',
  description: 'Options for how a webview that implements [IWithPopups](/webview/IWithPopups) handles popups.',
  members: [
    {
      name: 'LoadInOriginalWebView',
      type: 'value',
      description: (
        <div>
          The popup URL is automatically loaded into the original webview.
          This is the default behavior. In this mode, the <HashLink to="/webview/IWithPopups#PopupRequested"><code>PopupRequested</code></HashLink> event isn't raised.
        </div>
      )
    },
    {
      name: 'LoadInNewWebView',
      type: 'value',
      description: (
        <div>
          <p>
            The browser engine automatically creates a new webview for the popup
            and loads the popup URL into the new webview. The original webview then
            raises its <HashLink to="/webview/IWithPopups#PopupsRequested"><code>PopupRequested</code></HashLink> event
            and provides the new popup webview as <HashLink to="/webview/PopupRequestedEventArgs"><code>PopupRequestedEventArgs.WebView</code></HashLink>.
          </p>
          <p>
            Some authentication flows require this mode in order to function correctly. For example,
            auth flows like "Sign in with Google" open the signin page in a special popup
            that can relay the auth result back to the original page after authorization is finished.
            <code>LoadInNewWebView</code> must be used for flows like this, and the flow can not be emulated with
            the other popup modes.
          </p>
        </div>
      )
    },
    {
      name: 'NotifyWithoutLoading',
      type: 'value',
      description: (
        <div>
          The browser engine doesn't automatically create a new webview for the popup,
          but it still raises the <HashLink to="/webview/IWithPopups#PopupRequested"><code>PopupRequested</code></HashLink> event
          with a <HashLink to="/webview/PopupRequestedEventArgs#WebView"><code>PopupRequestedEventArgs.WebView</code></HashLink> of <code>null</code>.
        </div>
      )
    },

  ]
};
export default definition;
