import FileReferenceDefinition from '../FileReferenceDefinition';
import { getSharedPrefabMembers, getSharedPrefabDescription } from './prefab-shared';

const definition: FileReferenceDefinition = {
  name: 'WebViewPrefab',
  type: 'class',
  plainTextDescription: 'WebViewPrefab is a prefab that makes it easy to view and interact with an IWebView in 3D world space. It takes care of creating an IWebView, displaying its texture, and handling pointer interactions from the user.',
  description: `WebViewPrefab is a prefab that makes it easy to view and interact with an [IWebView](/webview/IWebView) in 3D world space. It takes care of creating an IWebView, displaying its texture, and handling pointer interactions from the user, like clicking, dragging, and scrolling. So, all you need to do is specify a URL or HTML to load, and then the user can view and interact with it. For use in a [Canvas](https://docs.unity3d.com/2020.1/Documentation/Manual/UICanvas.html), see [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab) instead.

${getSharedPrefabDescription('WebViewPrefab')}
  `,
  inheritsFrom: [ 'MonoBehaviour' ],
  seeAlso: [
    '[CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)',
    '[How clicking and scrolling works](https://support.vuplex.com/articles/clicking)',
    '[IWebView](/webview/IWebView)',
    '[Web (static methods)](/webview/Web)'
  ],
  members: [
    ...getSharedPrefabMembers(),
    {
      name: 'Collider',
      returns: 'Collider',
      type: 'property',
      propertyAccessors: { get: true },
      description: `Gets the prefab's collider`
    },
    {
      name: 'Instantiate',
      returns: 'WebViewPrefab',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'float',
          name: 'width'
        },
        {
          type: 'float',
          name: 'height'
        }
      ],
      description: "Creates a new instance with the given dimensions in Unity units. The [WebView](#WebView) property is available after initialization completes, which is indicated by WaitUntilInitialized() method. A WebViewPrefab's default resolution is 1300px per Unity unit but can be changed by setting [Resolution](#Resolution) property.",
      example: `// Create a 0.5 x 0.5 instance
var webViewPrefab = WebViewPrefab.Instantiate(0.5f, 0.5f);
// Position the prefab how we want it
webViewPrefab.transform.parent = transform;
webViewPrefab.transform.localPosition = new Vector3(0, 0f, 0.5f);
webViewPrefab.transform.LookAt(transform);
// Load a URL once the prefab finishes initializing
await webViewPrefab.WaitUntilInitialized();
webViewPrefab.WebView.LoadUrl("https://vuplex.com");`
    },
    {
      name: 'Instantiate',
      returns: 'WebViewPrefab',
      type: 'method',
      static: true,
      arguments: [
        {
          type: 'float',
          name: 'width'
        },
        {
          type: 'float',
          name: 'height'
        },
        {
          type: 'WebViewOptions',
          name: 'options'
        }
      ],
      description: "Like [Instantiate(float, float](#Instantiate), except it also accepts an object of options flags that can be used to alter the generated webview's behavior."
    },
    {
      name: 'Resize',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'float',
          name: 'width'
        },
        {
          type: 'float',
          name: 'height'
        }
      ],
      description: `Resizes the prefab mesh and webview to the given dimensions in Unity units.`,
      example: 'webViewPrefab.Resize(1.2f, 0.5f);',
      seeAlso: ['[Resolution](#Resolution)']
    },
    {
      name: 'WorldToNormalized',
      returns: 'Vector2',
      type: 'method',
      arguments: [
        {
          name: 'worldPoint',
          type: 'Vector3'
        }
      ],
      description: `Converts the given world point to a [normalized point](https://support.vuplex.com/articles/normalized-points) in the webview.`
    },
  ]
};
export default definition;
