import React, { Component } from 'react';
import Products from '../../models/products/Products';
import Product from '../../models/products/Product';
import ProductId from '../../models/products/ProductId';
import CustomersSection from '../CustomersSection';
import './styles.scss';
import Urls from '../../config/Urls';

export interface WebViewProductDescriptionProps {
  extraDescriptionParagraphHtml?: string;
  videoNotSupported?: boolean;
  numberOfDevelopers?: string;
  omitDeveloperSiteReference?: boolean;
  videoFeatureHtml?: string;
  interactionTypes?: {
    hover: boolean;
    drag: boolean;
  },
  native2DMode?: string|boolean;
  native2DModeOnly?: boolean;
  omitKeyboardFeature?: boolean;
  keyboardFeatureOverrideHtml?: string;
  omitNativeKeyboardFeature?: boolean;
  browserEngineFeatureHtml?: string;
  webViewApiFeatureExtraTextHtml?: string;
  webViewClassDocumentationUrl?: string;
  supportsPdfCreation?: boolean;
  supportsTransparentPages?: boolean;
  additionalFeaturesHtml?: string[];
  includeCustomersSection?: boolean;
  exampleOptions?: {
    examplesOverrideHtml?:string;
    omitBuiltInDemoScenes?: boolean;
    oculus?: boolean;
    arFoundation?: boolean;
    hololens?: boolean;
    popupDemo?: boolean;
    visionOS?: boolean;
    xrInteractionToolkit?: boolean;
  },
  systemRequirementsHtml?: string[]
  customSection?: {
    title: string;
    id: string;
    html: string;
  },
  notesAndLimitationsOptions?: {
    html: string[],
    precompiledLibrariesName: string;
    nameOfLibrariesThatCannotRunOnPc?: string;
    productIdForSinglePlatformWarning: ProductId;
    default3DRenderingLimitationsDisabled?: boolean;
    default3DRenderingLimitationsReasonHtml?: string
    default3DRenderingLimitations?: string[];
  },
}

export default class WebViewProductDescription extends Component<WebViewProductDescriptionProps> {

  render() {

    const { native2DModeOnly } = this.props;
    return (
      <div className="webview-description">
        <h2 className="description">
          <b>Easily display and interact with webpages in {native2DModeOnly ? '' : '3D or '}2D</b> using the Unity web browser
          plugin <a href="/webview/overview/#featured-customers">trusted by {this.props.numberOfDevelopers === undefined ? 'thousands of' : this.props.numberOfDevelopers} developers</a>. {this._renderDeveloperSiteReference()} {this._renderExtraDescriptionParagraphHtml()}
        </h2>
        <ul className="feature-list">
          <li style={{ listStyleType: "'🌐'" }}>Load a webpage <a href="https://developer.vuplex.com/webview/IWebView#LoadUrl">from a URL</a> or <a href="https://developer.vuplex.com/webview/IWebView#LoadHtml">HTML string</a></li>
          {this._renderVideoFeature()}
          <li style={{ listStyleType: "'<>'" }}><a href="https://blog.vuplex.com/how-to-create-unity-ui-with-html">Create UIs with HTML</a></li>
          {this._renderPrefabFeature()}
          {this._renderNative2DModeFeature()}
          {this._renderKeyboardFeature()}
          <li>C# source code for a <a href="https://developer.vuplex.com/webview/IWebView">unified API</a> that works seamlessly across <a href={Products.standalone.storeUrl}>Windows</a>, <a href={Products.standalone.storeUrl}>macOS</a>, <a href={Products.android.storeUrl}>Android</a>, <a href={Products.iOS.storeUrl}>iOS</a>, <a href={Products.visionOS.storeUrl}>visionOS</a>, <a href={Products.webGL.storeUrl}>WebGL</a>, and <a href={Products.uwp.storeUrl}>UWP</a> (each platform <a href="https://support.vuplex.com/articles/is-it-necessary-to-buy-for-each-platform">sold separately</a>)</li>
          {this._renderBrowserEngineFeature()}
          <li>Comprehensive APIs for <a href="https://developer.vuplex.com/webview/IWebView#methods-summary">controlling the browser</a> and listening to <a href="https://developer.vuplex.com/webview/IWebView#events-summary">browser events</a> {this.props.webViewApiFeatureExtraTextHtml ? <span dangerouslySetInnerHTML={{ __html: this.props.webViewApiFeatureExtraTextHtml}}/> : ''}</li>
          <li><a href="https://developer.vuplex.com/webview/IWebView#ExecuteJavaScript">Execute JavaScript</a></li>
          <li><a href="https://support.vuplex.com/articles/how-to-send-messages-from-javascript-to-c-sharp">Send messages from C# to JavaScript and vice versa</a></li>
          <li>View {this.props.supportsPdfCreation && <a href="https://developer.vuplex.com/webview/IWithPdfCreation">and create</a>} PDFs</li>
          <li>Integrate with <a href="https://support.vuplex.com/articles/custom-url-scheme">OAuth</a></li>
          {this._renderPlatformSpecificApiFeature()}
          {this._renderTransparentPagesFeature()}
          {this.props.additionalFeaturesHtml && (
            this.props.additionalFeaturesHtml.map(html => <li key={html.substring(0, 9)} dangerouslySetInnerHTML={{ __html: html }}/>)
          )}
        </ul>
        {this._renderCustomersSection()}
        <h2 id="examples">Examples</h2>
        {this._renderExamples()}
        <h2 id="system-requirements">System requirements</h2>
        {this._renderSystemRequirements()}
        {this._renderCustomSection()}
        <h2 id="notes-and-limitations">Important notes and limitations</h2>
        {this._renderNotesAndLimitations()}
      </div>
    );
  }

  private _getPlatformName(product: Product) {

    const platformName = product.name.split('for ')[1].split(' with')[0].split(' /')[0];
    return platformName;
  }

  private _render3DRenderingLimitations() {

    if (this.props.native2DModeOnly) {
      return;
    }
    const { notesAndLimitationsOptions } = this.props;
    if (!notesAndLimitationsOptions || notesAndLimitationsOptions.default3DRenderingLimitationsDisabled) {
      return;
    }
    const warningHtml = `Some HTML5 widget popups (like date picker inputs) aren't rendered. For more details, please see <a href="https://support.vuplex.com/articles/3d-rendering-limitations">this page</a>.`;
    const { native2DMode } = this.props;
    const { default3DRenderingLimitationsReasonHtml } = notesAndLimitationsOptions;
    if (!(native2DMode || default3DRenderingLimitationsReasonHtml)) {
      return (
        <li dangerouslySetInnerHTML={{ __html: warningHtml }} />
      );
    }

    const reason = default3DRenderingLimitationsReasonHtml ? <span dangerouslySetInnerHTML={{ __html: default3DRenderingLimitationsReasonHtml }}/>
      : <span>When not running in <a href="https://support.vuplex.com/articles/native-2d-mode">Native 2D Mode</a></span>;

    // Use concat to make a copy so that we don't add to the same array each time render() is called.
    const default3DRenderingLimitations = (notesAndLimitationsOptions.default3DRenderingLimitations || []).concat(warningHtml);
    return (
      <li>
        {reason}, this package has the following limitations:
        <ul>
          {default3DRenderingLimitations.map(this._render3DRenderingLimitation)}
        </ul>
      </li>
    );
  }

  private _render3DRenderingLimitation = (html: string) => {

    return (
      <li key={html.substr(0, 20)} dangerouslySetInnerHTML={{ __html: html }}>

      </li>
    )
  }

  private _renderArFoundationExample() {

    const { exampleOptions } = this.props;
    if (!exampleOptions || exampleOptions.arFoundation) {
      return (
        <li>
          <a href="https://github.com/vuplex/ar-foundation-webview-example">
            AR Foundation example
          </a>
        </li>
      );
    }
  }

  private _renderBrowserEngineFeature() {

    const browserEngineFeatureHtml = this.props.browserEngineFeatureHtml || `Powered by <a href="https://en.wikipedia.org/wiki/Chromium_(web_browser)">Chromium</a> (currently v125) on Windows and macOS and by the system webview on other platforms`;
    return <li><span dangerouslySetInnerHTML={{ __html: browserEngineFeatureHtml }}/></li>
  }

  private _renderBuiltInDemoScenes() {

    if (this.props.exampleOptions && this.props.exampleOptions.omitBuiltInDemoScenes) {
      return;
    }
    return (
      <li>3D WebView's included demo scenes:
        <ul style={{ fontSize: '14px' }}>
          <li>SimpleWebViewDemo</li>
          <li>CanvasWebViewDemo</li>
          <li>AdvancedWebViewDemo</li>
          <li>CanvasWorldSpaceDemo</li>
          {(!this.props.exampleOptions || this.props.exampleOptions.popupDemo) && <li>PopupDemo</li>}
        </ul>
      </li>
    );
  }

  private _renderCustomersSection() {

    if (this.props.includeCustomersSection) {
      return (
        <div>
          <h2 id="featured-customers">Featured Customers</h2>
          <CustomersSection/>
        </div>
      )
    }
  }

  private _renderCustomSection() {

    const { customSection } = this.props;
    if (customSection) {
      return (
        <div>
          <h2 id={customSection.id}>{customSection.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: customSection.html }}/>
        </div>
      )
    }
  }

  private _renderDeveloperSiteReference() {

    if (!this.props.omitDeveloperSiteReference) {
      return (
        <span>
          See the <a href="https://developer.vuplex.com">developer site</a> for full documentation.
        </span>
      );
    }
  }

  private _renderExamples() {

    const examplesOverrideHtml = this.props.exampleOptions && this.props.exampleOptions.examplesOverrideHtml;
    if (examplesOverrideHtml) {
      return <div dangerouslySetInnerHTML={{ __html: examplesOverrideHtml }}/>;
    }
    return (
      <ul>
        {this._renderBuiltInDemoScenes()}
        {this._renderOculusExample()}
        {this._renderXrInteractionToolkitExample()}
        {this._renderArFoundationExample()}
        {this._renderVisionOSExamples()}
        {this._renderHololensExample()}
      </ul>
    )
  }

  private _renderExtraDescriptionParagraphHtml() {

    const { extraDescriptionParagraphHtml } = this.props
    if (extraDescriptionParagraphHtml) {
      return <span dangerouslySetInnerHTML={{ __html: extraDescriptionParagraphHtml }}/>;
    }
  }

  private _renderHololensExample() {

    const { exampleOptions } = this.props;
    if (!exampleOptions || exampleOptions.hololens) {
      return (
        <li>
          <a href="https://github.com/vuplex/hololens-webview-example">
            Hololens example (MRTK2)
          </a>
        </li>
      );
    }
  }

  private _renderInterationTypes() {

    const interactions = ['click', 'scroll'];
    const { interactionTypes } = this.props;
    if (interactionTypes) {
      if (interactionTypes.hover) {
        interactions.push('hover');
      }
      if (interactionTypes.drag) {
        interactions.push('drag');
      }
    } else {
      interactions.push('hover', 'drag');
    }

    return `(${interactions.join(', ')})`;
  }

  private _renderKeyboardFeature() {

    if (this.props.omitKeyboardFeature) {
      return;
    }
    if (this.props.keyboardFeatureOverrideHtml) {
      return <li style={{ listStyleType: "'⌨️'" }} dangerouslySetInnerHTML={{ __html: this.props.keyboardFeatureOverrideHtml }}></li>;
    }
    return (
      <li style={{ listStyleType: "'⌨️'" }}>Type with the included <a href="https://developer.vuplex.com/webview/Keyboard">on-screen keyboard</a> {!this.props.omitNativeKeyboardFeature && <span>or native keyboard</span>}</li>
    )
  }

  private _renderNative2DModeFeature() {

    const { native2DMode } = this.props;
    if (!native2DMode) {
      return;
    }
    const extraText = typeof native2DMode === 'string' ? ` ${native2DMode}` : undefined;
    return (
      <li style={{ listStyleType: "'📱'" }}>Includes a <a href="https://support.vuplex.com/articles/native-2d-mode">Native 2D Mode</a>{extraText}</li>
    )
  }

  private _renderNotesAndLimitations() {

    const { notesAndLimitationsOptions } = this.props;
    if (notesAndLimitationsOptions) {
      const { productIdForSinglePlatformWarning } = notesAndLimitationsOptions;
      const supportedPlatformName = this._getPlatformName(Products.get(productIdForSinglePlatformWarning)!);
      const productIdForBundle = notesAndLimitationsOptions.productIdForSinglePlatformWarning || ProductId.UnityWebViewStandalone;
      const bundleUrl = `${Urls.Store}/cart/?products=${productIdForBundle}`;

      return (
        <ul>
          <li>Products purchased through the Vuplex Store are downloaded through the <a href={Urls.Dashboard}>Vuplex Developer Dashboard</a>. For a comparison of the Vuplex Store vs. the Unity Asset Store, please see <a href="https://support.vuplex.com/articles/store-comparison">this page</a>.</li>
          <li>
            This package only supports {supportedPlatformName}. To support other platforms, you can <a href="https://support.vuplex.com/articles/is-it-necessary-to-buy-for-each-platform">install additional 3D WebView packages</a>, and you can save money on multiple packages by <a href={bundleUrl}>buying a bundle</a>. All of the 3D WebView packages work seamlessly together, so all you need to do is install them into the same project, and then 3D WebView automatically detects and uses the correct plugin at runtime and build time.
          </li>
          <li>
            3D WebView's native {notesAndLimitationsOptions.precompiledLibrariesName} plugins are provided as precompiled libraries, and the native source code for them is not provided.
          </li>
          {notesAndLimitationsOptions.nameOfLibrariesThatCannotRunOnPc && <li>3D WebView's native {notesAndLimitationsOptions.nameOfLibrariesThatCannotRunOnPc} plugins can't run in the editor, so a <a href="https://support.vuplex.com/articles/mock-webview">mock webview implementation</a> is used by default while running in the editor. However, you can actually load and render real web content in the editor by also installing <a href={Products.standalone.storeUrl}>3D WebView for Windows and macOS</a> into the project. You can save money by buying both packages together <a href="https://support.vuplex.com/articles/bundles">in a bundle</a>.</li>}
          {this._render3DRenderingLimitations()}
          {notesAndLimitationsOptions.html.map(n => <li key={n.substr(0, 50)}><span dangerouslySetInnerHTML={{ __html: n }} /></li>)}
        </ul>
      );
    }
    return (
      <ul>
        <li>
          Licensed primarily under the <a href="https://vuplex.com/commercial-library-license">Vuplex Commercial Library License</a> with additional licenses for open source components where noted.
        </li>
        <li>
          3D WebView's native plugins are provided as precompiled libraries, and the native source code for them is not included.
        </li>
        <li>
          <a href={Products.standalone.storeUrl + '#notes-and-limitations'}>Windows / macOS limitations</a>
        </li>
        <li>
          <a href={Products.android.storeUrl + '#notes-and-limitations'}>Android limitations</a>
        </li>
        <li>
          <a href={Products.androidGecko.storeUrl + '#notes-and-limitations'}>Android Gecko limitations</a>
        </li>
        <li>
          <a href={Products.iOS.storeUrl + '#notes-and-limitations'}>iOS limitations</a>
        </li>
        <li>
          <a href={Products.visionOS.storeUrl + '#notes-and-limitations'}>visionOS limitations</a>
        </li>
        <li>
          <a href={Products.webGL.storeUrl + '#notes-and-limitations'}>WebGL limitations</a>
        </li>
        <li>
          <a href={Products.uwp.storeUrl + '#notes-and-limitations'}>Universal Windows Platform limitations</a>
        </li>
      </ul>
    );
  }

  private _renderSystemRequirements() {

    const { systemRequirementsHtml } = this.props;
    if (systemRequirementsHtml) {
      return (
        <ul>
          {systemRequirementsHtml.map(r => <li key={r.substr(0, 50)}><span dangerouslySetInnerHTML={{ __html: r }}/></li>)}
        </ul>
      );
    }
    return (
      <ul>
        <li>
          <a href={Products.standalone.storeUrl + '#system-requirements'}>Windows / macOS requirements</a>
        </li>
        <li>
          <a href={Products.android.storeUrl + '#system-requirements'}>Android requirements</a>
        </li>
        <li>
          <a href={Products.androidGecko.storeUrl + '#system-requirements'}>Android Gecko requirements</a>
        </li>
        <li>
          <a href={Products.iOS.storeUrl + '#system-requirements'}>iOS requirements</a>
        </li>
        <li>
          <a href={Products.visionOS.storeUrl + '#system-requirements'}>visionOS requirements</a>
        </li>
        <li>
          <a href={Products.webGL.storeUrl + '#system-requirements'}>WebGL requirements</a>
        </li>
        <li>
          <a href={Products.uwp.storeUrl + '#system-requirements'}>Universal Windows Platform requirements</a>
        </li>
      </ul>
    );
  }

  private _renderOculusExample() {

    const { exampleOptions } = this.props;
    if (!exampleOptions || exampleOptions.oculus) {
      return (
        <li>
          <a href="https://github.com/vuplex/oculus-webview-example">
            Meta Quest example
          </a>
        </li>
      );
    }
  }

  private _renderPlatformSpecificApiFeature() {

    const { webViewClassDocumentationUrl } = this.props;
    if (webViewClassDocumentationUrl) {
      return <li>Includes additional APIs for <a href={webViewClassDocumentationUrl}>platform-specific features</a></li>
    }
    return <li>Includes additional APIs for <a href="https://developer.vuplex.com/webview/additional-interfaces">advanced features</a> and <a href="https://developer.vuplex.com/webview/platform-specific">platform-specific features</a></li>
  }

  private _renderPrefabFeature() {

    const { native2DModeOnly } = this.props;
    let content;
    if (native2DModeOnly) {
      content = <span>Get started fast with the 2D <a href="https://developer.vuplex.com/webview/CanvasWebViewPrefab">CanvasWebViewPrefab</a></span>;
    } else {
      content = (
        <span>
          Get started fast with the 3D <a href="https://developer.vuplex.com/webview/WebViewPrefab">WebViewPrefab</a> or 2D <a href="https://developer.vuplex.com/webview/CanvasWebViewPrefab">CanvasWebViewPrefab</a>, which
          render to a Texture2D and <a href="https://support.vuplex.com/articles/clicking">handle user interactions</a> {this._renderInterationTypes()}
        </span>
      )
    }
    return <li style={{ listStyleType: "'⚡️'" }}>{content}</li>;
  }

  private _renderTransparentPagesFeature() {

    if (this.props.supportsTransparentPages) {
      return <li>Supports <a href="https://support.vuplex.com/articles/how-to-make-a-webview-transparent">transparent pages</a></li>
    }
  }

  private _renderVideoFeature() {

    if (!this.props.videoNotSupported) {
      const content = this.props.videoFeatureHtml ? <span dangerouslySetInnerHTML={{ __html: this.props.videoFeatureHtml }} />
                                                  : 'Watch videos and YouTube';
      return <li style={{ listStyleType: "'📺'" }}>{content}</li>;
    }
  }

  private _renderVisionOSExamples() {

    const { exampleOptions } = this.props;
    if (!exampleOptions || exampleOptions.visionOS) {
      return (
        <li>visionOS examples for <a href="https://github.com/vuplex/visionos-vr-webview-example">virtual reality</a> and <a href="https://github.com/vuplex/visionos-mr-webview-example">mixed reality</a></li>
      );
    }
  }

  private _renderXrInteractionToolkitExample() {

    const { exampleOptions } = this.props;
    if (!exampleOptions || exampleOptions.xrInteractionToolkit) {
      return (
        <li>
          <a href="https://github.com/vuplex/xrit-webview-example">
            XR Interaction Toolkit example
          </a>
        </li>
      );
    }
  }
}
