import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'VisionOSWebView',
  type: 'class',
  description: `VisionOSWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.visionOS.markdownLink}. It also includes additional APIs for visionOS-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithDeepLinking',
    'IWithDownloads',
    'IWithFallbackVideo',
    'IWithMovablePointer',
    'IWithNativeJavaScriptDialogs',
    'IWithNativeOnScreenKeyboard',
    'IWithPdfCreation',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'WindowClosed',
      returns: 'EventHandler',
      type: 'event',
      description: "When the instance is created with [VisionOSWebView.CreateInWindow()](#CreateInWindow), this event indicates when the windowed webview is closed by the user. When the instance is not created with VisionOSWebView.CreateInWindow() (e.g. it's created by a [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)), this event is not used",
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    webView.WindowClosed += (sender, eventArgs) => {
        Debug.Log("WebView window closed");
    };
#endif`,
    },
    {
      name: 'CreateInWindow',
      type: 'method',
      returns: 'Task<VisionOSWebView>',
      description: "When running in Mixed Reality, the application can use this method to open a webview in a native visionOS (SwiftUI) window, which the user can reposition, resize, and close using visionOS's native windowing controls. This approach provides the best performance, and since it supports hovering over elements in the web page via eye tracking, it also provides the best user experience. The tradeoff is that the application is unable to control the webview's position or size because those aspects are controlled by the user. This method is only supported when the application's app mode is set to Mixed Reality, and it throws an InvalidOperationException if invoked when the app mode is set to Virtual Reality or Windowed. For a full example, please see the [visionOS Mixed Reality WebView Example](https://github.com/vuplex/visionos-mr-webview-example) project.",
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // The webview has been displayed to the user in a native window,
    // and now you can use IWebView APIs like LoadUrl() and LoadHtml().
    webView.LoadUrl("https://vuplex.com");
#endif`,
      seeAlso: [
        '[VisionOSWebView.WindowClosed](#WindowClosed)',
        '[VisionOSWebView.SetScrollViewBounces](#SetScrollViewBounces)',
        '[VisionOSWebView.SetFullscreenEnabled](#SetFullscreenEnabled)',
      ]
    },
    {
      name: 'GetNativeWebView',
      type: 'method',
      returns: 'IntPtr',
      description: "Returns a pointer to the instance's native Objective-C [WKWebView](https://developer.apple.com/documentation/webkit/wkwebview?language=objc).",
      warnings: ["Adding code that interacts with the native WKWebView directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetNativeWebView() if truly necessary. If 3D WebView is missing an API that you need, feel free to [contact us](https://vuplex.com/contact)."],
      example: [
        {
          title: 'Objective-C Example',
          code: `// Example of defining a native Objective-C function that sets WKWebView.allowsLinkPreview.
// Place this in a .m file in your project, like Assets/Plugins/WebViewCustom.m
#import <Foundation/Foundation.h>
#import <WebKit/WebKit.h>

void WebViewCustom_SetAllowsLinkPreview(WKWebView *webView, BOOL allowsLinkPreview) {

    webView.allowsLinkPreview = allowsLinkPreview;
}`
        },
        {
          title: 'C# Example',
          code: `// Example of calling the native Objective-C function from C#.
async void EnableLinkPreviews(WebViewPrefab webViewPrefab) {

    await webViewPrefab.WaitUntilInitialized();
    #if UNITY_VISIONOS && !UNITY_EDITOR
        var wkWebViewPtr = (webViewPrefab.WebView as VisionOSWebView).GetNativeWebView();
        WebViewCustom_SetAllowsLinkPreview(wkWebViewPtr, true);
    #endif
}

[System.Runtime.InteropServices.DllImport("__Internal")]
static extern void WebViewCustom_SetAllowsLinkPreview(System.IntPtr webViewPtr, bool allowsLinkPreview);`
        }
      ]
    },
    {
      name: 'SetCameraEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the camera without enabling the microphone. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the camera.",
      example: `void Awake() {
    #if UNITY_VISIONOS && !UNITY_EDITOR
        VisionOSWebView.SetCameraEnabled(true);
    #endif
}`
    },
    {
      name: 'SetFullscreenEnabled',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "When the instance is created with [VisionOSWebView.CreateInWindow()](#CreateInWindow), this method sets whether web pages can use the [JavaScript Fullscreen API](https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API) to make an HTML element occupy the entire webview. The default is `true`, meaning that the JavaScript Fullscreen API is enabled by default. When the instance is not created with VisionOSWebView.CreateInWindow() (e.g. it's created by a [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)), this method has no effect because the JavaScript Fullscreen API is only support for webviews created with VisionOSWebView.CreateInWindow().",
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    // Disable the JavaScript Fullscreen API.
    webView.SetFullscreenEnabled(false);
#endif`,
      seeAlso: '[Fullscreen support in 3D WebView](https://support.vuplex.com/articles/fullscreen)'
    },
    {
      name: 'SetMicrophoneEnabled',
      returns: 'void',
      static: true,
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      description: "Like [Web.SetCameraAndMicrophoneEnabled()](/webview/Web#SetCameraAndMicrophoneEnabled), but enables only the microphone without enabling the camera. In addition to calling this method, you must also complete the additional steps described [here](https://support.vuplex.com/articles/webrtc#ios) in order to successfully enable the microphone.",
      example: `void Awake() {
    #if UNITY_VISIONOS && !UNITY_EDITOR
        VisionOSWebView.SetMicrophoneEnabled(true);
    #endif
}`
    },
    {
      name: 'SetScrollViewBounces',
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'bool',
          name: 'bounces'
        }
      ],
      description: "When the instance is created with [VisionOSWebView.CreateInWindow()](#CreateInWindow), this method sets whether the scroll view bounces past the edge of content and back again. The default is `true`. When the instance is not created with VisionOSWebView.CreateInWindow() (e.g. it's created by a [CanvasWebViewPrefab](/webview/CanvasWebViewPrefab)), this method has no effect.",
      example: `#if UNITY_VISIONOS && !UNITY_EDITOR
    var webView = await VisionOSWebView.CreateInWindow();
    webView.SetScrollViewBounces(false);
#endif`
    },
    {
      name: 'SetTargetFrameRate',
      type: 'method',
      returns: 'void',
      arguments: [
        {
          name: 'targetFrameRate',
          type: 'uint'
        }
      ],
      description: "Sets the target web frame rate. The default is `30`, which is also the maximum value. This method can be used to lower the target web frame rate in order to decrease energy and CPU usage. 3D WebView's rendering speed is limited by the speed of the underlying visionOS APIs, so the actual web frame rate achieved is always lower than the default target of 30 FPS.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_VISIONOS && !UNITY_EDITOR
    var visionOSWebView = webViewPrefab.WebView as VisionOSWebView;
    visionOSWebView.SetTargetFrameRate(15);
#endif`,
    },
  ]
};
export default definition;

